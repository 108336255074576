@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Modern+Antiqua&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Just+Me+Again+Down+Here&family=Modern+Antiqua&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
  background-color: #282828;
}

::-webkit-scrollbar-thumb {
  background-color: #f5d29e;
  width: 2px;
}

::-webkit-scrollbar {
  width: 2px;
}

.scroll-anim {
  animation: scroll 10s linear infinite;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: initial;
  width: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.black-gradient {
  background: radial-gradient(
    161.21% 160.39% at 5.71% -44.13%,
    #363636 0%,
    rgba(26, 26, 26, 0.27) 100%
  );
}
.golden-text-main {
  background-image: linear-gradient(165deg, #fed29e 20%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.golden-actually-main {
  background-image: linear-gradient(145deg, #fed29e 70%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.golden-strip-main {
  background-image: linear-gradient(145deg, #fed29e 70%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.golden-woody-main {
  background-image: linear-gradient(126deg, #fed29e 70%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.golden-moana-main {
  background-image: linear-gradient(126deg, #fed29e 70%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.golden-rocket-main {
  background-image: linear-gradient(157deg, #fed29e 70%, #975a37 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.others-strip-main {
  background-image: linear-gradient(to right, #fffbf6 0%, #5b5a59 100%);
}
.pixa-strip-main {
  background-image: linear-gradient(
    to right,
    #fffbf6 0%,
    #fffbf6 15%,
    #edbe8d 45%,
    #d6a77a 65%,
    #c99469 100%
  );
}
.video-black-bg {
  background: linear-gradient(180deg, transparent, #1b1717);
}
.bg-text-for-engine {
  background: linear-gradient(135deg, #fff 0%, #71717a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-responsive {
  font-size: clamp(1.375rem, 4.5vw, 3.5rem);
}

.leading-responsive {
  line-height: clamp(2.25rem, 6vw, 6rem);
}

.text-button-responsive {
  font-size: clamp(1rem, 2vw, 2.5rem);
}

.hero-grad {
  background: linear-gradient(to bottom, #ffe0b2, #ffbf73);
}

/* for main-bg images */
.bg-moon-bg-pattern-css {
  background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1723728684/pixa-pie/hero/half_moon_bg.webp');
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
}
/* min-h-screen max-lg:min-h-0 max-lg:h-[650px] max-md:h-[550px] max-sm:h-[350px] max-xs:h-[300px] */
@media (max-width: 440px) {
  .bg-moon-bg-pattern-css {
    background-size: calc(100vw + 100px) 320px;
    height: 320px;
  }
  .card-cont-height {
    height: 220px;
  }
  .pixa-msg {
    padding: 2px 2px 2px 2px;
    height: min-content;
    width: 110px;
  }
  .animation-height {
    height: 300px;
    min-height: min-content;
  }
}
.slick-dots li button:before {
  padding-top: 8px;
  font-size: 16px;
  color: #ff6079;
}

.slick-dots li.slick-active button:before {
  color: #ff6079;
}
@media (min-width: 440px) and (max-width: 640px) {
  .bg-moon-bg-pattern-css {
    background-size: calc(100vw + 50px) 350px;
    height: 350px;
  }
  .card-cont-height {
    height: 300px;
  }
  .pixa-msg {
    padding: 4px 4px 4px 4px;
    height: 60px;
    width: 160px;
  }
  .animation-height {
    height: 350px;
    min-height: min-content;
  }
}
@media (min-width: 641px) and (max-width: 976px) {
  .bg-moon-bg-pattern-css {
    background-size: calc(100vw + 40px) 450px;
    height: 450px;
  }
  .card-cont-height {
    height: 450px;
  }
  .pixa-msg {
    padding: 4px 12px 12px 12px;
    height: 80px;
    width: 240px;
  }
  .animation-height {
    height: 550px;
    min-height: min-content;
  }
}
@media (min-width: 977px) and (max-width: 1140px) {
  .animation-height {
    height: 650px;
    min-height: min-content;
  }
  .bg-moon-bg-pattern-css {
    background-size: calc(100vw + 40px) 450px;
    height: 450px;
  }
  .card-cont-height {
    height: 450px;
  }
  .pixa-msg {
    padding: 4px 12px 12px 12px;
    height: 80px;
    width: 240px;
  }
}
@media (min-width: 1140px) and (max-width: 1280px) {
  .bg-moon-bg-pattern-css {
    background-size: 100% 580px;
    height: 580px;
  }
  .card-cont-height {
    height: 550px;
  }
  .animation-height {
    height: 100vh;
    min-height: min-content;
  }
}
@media (min-width: 1281px) and (max-width: 1800px) {
  .bg-moon-bg-pattern-css {
    background-size: 100% 580px;
    height: 580px;
  }
  .card-cont-height {
    height: 550px;
  }
  .animation-height {
    height: 100vh;
    min-height: min-content;
  }
}
@media (min-width: 1801px) {
  .bg-moon-bg-pattern-css {
    background-size: 100% 740px;
    height: 740px;
  }
  .card-cont-height {
    height: 740px;
  }
  .animation-height {
    min-height: 100vh;
    min-height: min-content;
  }
}

@media (min-width: 1023px) and (max-width: 1279px) {
  .actual-font-for-lg-screen {
    width: 80%;
  }
  .feat-section {
    display: hidden;
  }
}
@media (min-width: 1280px) and (max-width: 1799px) {
  .actual-font-for-lg-screen {
    width: 70%;
  }
}
@media (min-width: 1800px) {
  .actual-font-for-lg-screen {
    width: 70%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  /*  */
}
@media (min-width: 1280px) and (max-width: 1799px) {
  /*  */
}
@media (min-width: 1800px) {
  /*  */
}

/* @media only screen and (min-width: 640px) {
  .only-show-on-mobile-screen {
    display: none;
  }
}
@media (min-width: 1279px) and (max-width: 640px) {
  .only-show-on-md-screen {
    display: none;
  }
}
@media (min-width: 1340px) and (max-width: 1280px) {
  .only-show-on-lg-screen {
    display: none;
  }
} */

@media only screen and (min-width: 641px) {
  .only-show-on-mobile-screen {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .only-show-on-md-screen {
    display: none;
  }
}
@media only screen and (min-width: 1279px) {
  .only-show-on-md-screen {
    display: none;
  }
}
@media only screen and (max-width: 1279px) {
  .only-show-on-lg-screen {
    display: none;
  }
}
@media only screen and (min-width: 1340px) {
  .only-show-on-lg-screen {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .width-for-btw-large-and-medium-screen {
    width: 90%;
  }
}

.product-scrollbar::-webkit-scrollbar {
  display: none;
}

.product-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes growWidth {
  from {
    width: 0%;
  }
  to {
    width: var(--feature-width);
  }
}

.animate-growWidth {
  animation: growWidth 2s ease-in-out forwards;
}

/* text animation */
.char-animation {
  animation: text-animation 1s ease-out 1 both;
  animation-fill-mode: forwards;
}

@keyframes text-animation {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-35px, -40px, -150px)
      rotate3d(1, -1, 0, 35deg);
  }
  to {
    opacity: 1;
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}

.alpha-list {
  list-style-type: upper-alpha;
  padding-left: 1.25rem;
}

@media (min-width: 1024px) {
  .checkout-bg {
    background-color: rgba(255, 96, 121, 0.08);
  }
}

@media (max-width: 1023px) {
  .checkout-bg {
    background-color: transparent;
  }
}

.sale-pitch {
  box-shadow: 0px -11px 10px 0px rgba(55, 55, 55, 0.05);
}

.custom-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.custom-radio:checked {
  background-color: #fff; /* Pink color */
  border-color: #fff;
}

.custom-radio:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-color: #ff6079;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.custom-radio:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.4);
}

.pill-bg {
  background: linear-gradient(180deg, #ecc79b 0.02%, #b98159 99.98%);
}

.prem-card-bg {
  background: linear-gradient(163deg, #ff899c -23.18%, #ff6079 88.37%), #d9d9d9;
}

.prem {
  background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724343319/pixa-pie/product-premium/i1rwgfucmvjopssmwfef.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Large screens (Desktops and larger laptops, 1200px and above) */
@media (min-width: 1340px) {
  .prem {
    width: 60vw;
    height: 80vh;
    max-height: 600px;
    background-size: 100% 100%;
  }
}

/* Medium screens (Tablets, small laptops, 768px to 1199px) */
@media (min-width: 1096px) and (max-width: 1339px) {
  .prem {
    width: 65vw;
    height: 70vh;
    background-size: 100% 100%;
  }
}

/* Medium screens (Tablets, small laptops, 768px to 1199px) */
@media (min-width: 996px) and (max-width: 1095px) {
  .prem {
    width: 75vw;
    height: 42vh;
    min-height: 550px;
    background-size: 100% 100%;
    padding: 30px;
  }
}

@media (min-width: 768px) and (max-width: 995px) {
  .prem {
    /* get new vertical image of Ipad */
    background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724348791/pixa-pie/product-premium/l9bg4p3fpjdie1rwgawr.webp');
    width: 70vw;
    height: 100%;
    background-size: 100% 100%;
    padding: 32px;
    flex-direction: column;
  }
  .prem-right {
    width: 100%;
    border-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .prem-left {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
}

/* Small screens (Large phones, 600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {
  .prem {
    /* get new vertical image of Ipad */
    background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724348791/pixa-pie/product-premium/l9bg4p3fpjdie1rwgawr.webp');
    width: 80vw;
    height: 100%;
    background-size: 100% 100%;
    padding: 32px;
    flex-direction: column;
  }
  .prem-right {
    width: 100%;
    border-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .prem-left {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
}

/* Extra small screens (Phones, less than 600px) */
@media (max-width: 599px) {
  .prem {
    /* get new vertical image of Ipad */
    background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1724348791/pixa-pie/product-premium/l9bg4p3fpjdie1rwgawr.webp');
    width: 90vw;
    height: 100%;
    background-size: 100% 100%;
    padding: 19px;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: column;
  }
  .prem-right {
    width: 100%;
    border-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .prem-left {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* Extra large screens (4K displays and larger, 1800px and above) */
@media (min-width: 1800px) {
  .prem {
    width: 50vw;
    height: 60vh;
    background-size: 100% 100%;
  }
}

.congrat-wrapper {
  background: linear-gradient(
    to right,
    #0b0b0b 0%,
    #666666 20%,
    #0b0b0b 80%,
    #666565 40%,
    #666666 60%,
    #ffffff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.congrat-btn {
  border-radius: var(--L, 16px);
  background: linear-gradient(
      269deg,
      #0b0b0b -5.08%,
      #494747 60.47%,
      #0b0b0b 106.4%
    ),
    var(--grey-white, #fff);
  box-shadow: 0px -4px 4px 0px rgba(255, 255, 255, 0.45) inset,
    0px -5px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.cert-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.cert {
  width: 100%;
  padding-top: 66.67%;
  position: relative;
  background-image: url('https://res.cloudinary.com/dx6dieevo/image/upload/f_auto,q_auto:good,w_auto/v1725198056/pixa-pie/certificate/doeel8tgurroji2gfgwk.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cert-content {
  position: absolute;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 10%;
  padding-top: 8%;
  padding-bottom: 5%;
  box-sizing: border-box;
}

.cert-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  margin-top: 2%;
}

.cert-title {
  font-size: clamp(14px, 3.5vw, 36px);
  color: #424242;
  font-weight: 500;
  /* width: 100%; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
  line-height: clamp(1.2, 2vw, 1.6);
}

.cert-title-download {
  font-size: clamp(14px, 3.5vw, 36px);
  line-height: clamp(1.2, 2vw, 1.6);
  color: #424242;
  font-weight: 500;
  margin-top: -9%;
  margin-bottom: 1%;
}

.cert-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cert-subtitle {
  font-size: clamp(6px, 1.5vw, 16px);
  margin-bottom: 2%;
}

.cert-name {
  font-size: clamp(14px, 4.5vw, 52px);
  font-weight: 500;
}

.cert-underline {
  width: 60%;
  height: 2px;
  background-color: #000;
  border-radius: 8px;
}

.cert-description {
  font-size: clamp(6px, 1.5vw, 16px);
  margin: 2% 0;
  padding-left: 8%;
  padding-right: 8%;
}

.cert-message {
  font-size: clamp(8px, 2vw, 20px);
  margin-bottom: 2%;
  margin-top: 2%;
}

.cert-footer {
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cert-footer-item {
  width: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cert-date,
.cert-label {
  font-size: clamp(6px, 1.5vw, 20px);
}

.cert-seal {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.cert-signature {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.special-cert-name {
  margin-top: 4%;
  margin-bottom: 4%;
  line-height: 0;
}

@media (max-width: 768px) {
  .cert-content {
    padding: 5%;
  }

  .cert-header {
    padding-top: 3%;
  }
  .cert-subtitle {
    margin-top: 2%;
  }
  .cert-name {
    margin-top: -1%;
  }
  .cert-description {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
  }
  .cert-message {
    margin-bottom: 0%;
    margin-top: -1%;
  }
  .cert-footer {
    width: 75%;
    margin-top: 2%;
  }

  .cert-footer-item {
    width: 100%;
    margin-bottom: 2%;
  }

  .cert-seal,
  .cert-signature {
    max-width: 30px;
    max-height: 30px;
  }
  .cert-underline {
    height: 1px;
  }
}
@media (min-width: 1096px) and (max-width: 1339px) {
  .cert-footer {
    margin-top: 4%;
  }
  .cert-message {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

/* Medium screens (Tablets, small laptops, 768px to 1199px) */
@media (min-width: 996px) and (max-width: 1095px) {
  .cert-header {
    margin-top: -2%;
  }
  .cert-name {
    margin-top: -1%;
  }
  .cert-message {
    margin-top: 0%;
    margin-bottom: 2%;
  }
  .cert-footer {
    margin-top: 4%;
  }
}

@media (min-width: 768px) and (max-width: 995px) {
  .cert-header {
    margin-top: -2%;
  }
  .cert-seal,
  .cert-signature {
    max-width: 50px;
    max-height: 50px;
  }
  .cert-name {
    margin-top: -1%;
  }
  .cert-message {
    margin-top: 0%;
    margin-bottom: 2%;
  }
  .cert-footer {
    margin-top: 2%;
  }
}

@media (min-width: 1800px) {
  .cert-container {
    max-width: 1600px;
  }
  .cert-footer {
    margin-top: 4%;
  }
  .cert-message {
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .cert-header {
    margin-top: 4%;
  }
}

.custom-skeleton {
  --base-color: #ffdde3 !important;
  --highlight-color: #ffffff !important;
  background-image: linear-gradient(
    90deg,
    var(--base-color) 0%,
    var(--highlight-color) 53%,
    var(--base-color) 100%
  ) !important;
  background-size: 200% 100% !important;
  animation: shimmer 1.5s infinite !important;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
